<template>
     <div class="userList">
        <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >受检管理</el-breadcrumb-item>
            <el-breadcrumb-item>受检人档案列表</el-breadcrumb-item>
        </el-breadcrumb>
       
        <div class="content">
          <!-- top_select -->
          <div class="selectbox">
                <div class="tple1" style="width:3.08rem">
                    <div>
                        <span class="seachText">受检人编号：</span><el-input style="width: 2.20rem;" clearable placeholder="请输入编号/身份证号" v-model="searchInfo.cardId"  oninput ="value=value.replace(/[^0-9A-Za-z.]/g,'')"></el-input>
                    </div>
                </div>
                <div class="tple1" style="width:2.42rem">
                     <div>
                        <span class="seachText" style="margin-left:0.22rem">性别：</span>
                        <el-select v-model="searchInfo.gender" class="serch_input">
                            <el-option
                                v-for="item in sexOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                   
                </div>
                <div class="tple1" style="width:3.08rem">
                     <div>
                       <span class="seachText">手机号：</span>
                        <el-input style="width: 2.20rem;" clearable placeholder="请输入手机号" v-model="searchInfo.mobile"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </div>
                </div>
                <div class="tple1" style="width:3.08rem">
                    <div>
                        <span class="seachText" style="margin-left:0.11rem">检测日期：</span>
                        <el-date-picker style="width: 2.20rem;" v-model="searchInfo.examinaTime" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" ></el-date-picker>
                    </div>
                </div>
                
          </div>
          <!-- 查询框/重置框 -->
         <div class="seach">
             <div class="start" @click="startSeach">查询</div>
             <div class="reset" @click="clear">重置</div>
         </div>
            <!-- tableTop -->
            <div class="tableTop">
                <div class="tableTop_left"></div>
                <div class="tableTop_right">
                    <div class="search">
                    <el-input
                        placeholder="请输入内容"
                        prefix-icon="el-icon-search"
                        @change="search"
                        v-model="seachcon"
                        class="elSerch"
                        >
                        <template slot="append" style="background-color: #00CCCC;cursor:pointer">搜索</template>
                    </el-input>
                    </div>
                <div class="delete" @click="remove">删除</div>
                </div>
            </div>
            <CheckPeopleTable ref="cpt"/>
        </div>
     </div>
</template>
<script>
import CheckPeopleTable from '@/components/table/userListTable.vue'
import { district } from '@/assets/js/cityCode.js'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    name:'userList',
    components:{
        CheckPeopleTable,
    },
    data(){
        return{
            
            //搜索查询信息 
           searchInfo:{
               cardId:null,
			   gender:null,
			   mobile:null,
			   examinaTime:[],
            },
            // 表头搜索框的任意搜索
             seachcon:null,
            //sex
            sexOptions: [ 
                        {
                        label:'全部',
                        value: null
                        },
                        {
                            label:'男',
                            value: 1
                        }, 
                        {
                            label:'女',
                            value: 0,
                        }],
            // 地区 code 
            district,
        }
    },
    methods:{
        //顶部搜索
        startSeach(){
           //console.log("搜索",this.searchInfo);
            this.$refs.cpt.getData(1,10,this.searchInfo.cardId,this.searchInfo.gender,this.searchInfo.mobile,null,this.searchInfo.examinaTime[0],this.searchInfo.examinaTime[1])
        },
        clear(){
            Object.keys(this.searchInfo).forEach((key,value)=>{
                console.log(key);
                if(key=='examinaTime'){
                    this.searchInfo[key]=[];
                }else{
                    this.searchInfo[key]=null
                }
                
            });
           // console.log(this.searchInfo)
        },

        // 开始查询
        search(){
            console.log("查询")
            this.$refs.cpt.getData(1,10,null,null,null,this.seachcon,null,null);
        },
        remove(){
            this.$message.error("暂不支持");
        },
       
    }
}
</script>
<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__icon {
    line-height: 0 !important;
}

/deep/.el-icon-date:before{
    line-height: 0.2rem !important;
    font-size:0.11rem
}

/deep/.el-input__inner{
    height: .30rem !important;
    font-size: .11rem !important
  }
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
}
/deep/.el-button{
    padding: 0px 0 0 4px !important;
    text-align: center !important;
    color: #fff !important;
    font-size: 0.11rem !important;
  }
/deep/.el-date-editor .el-range-input{
    font-size: .11rem !important
}


// component style 
    //联合搜索框样式
.elSerch>>>.el-input__inner
    border: 1px solid #00CCCC !important
    width: 200px
    border-radius: 4px 0 0 4px

    
// 正常嵌套样式写法
.content
    overflow hidden
    position: relative
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
    /*newtop */
    .selectbox
        display: flex
        justify-content: space-between
        .tple1
            height: .69rem
            .seachText
                font-size: .11rem
                color: #AEAEAE
            .serch_input
                width:1.56rem !important 
    .seach
        width: 200px 
        height: 40px 
        position: absolute
        right: 110px
        top:80px
        display: flex
        .start
            width: 64px
            height: 32px
            background-color: #00cccc
            text-align: center
            line-height:32px
            color: #fff
            font-size: 14px
            margin-left: 16px
            cursor pointer
            border-radius: 4px
        .reset
            width: 64px
            height: 32px
            background-color: #ffffff
            text-align: center
            line-height:32px
            color: #858585
            border: 1px solid #858585
            font-size: 14px
            margin-left: 16px
            cursor pointer
            border-radius: 4px


    .tableTop 
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 60px
        .tableTop_left
            display: flex
            .downloadReport
                width: 108px
                height: 32px
                background-color: #00CCCC
                line-height: 32px
                font-size: 14px
                text-align: center
                color: #ffffff
                border-radius: 2px
                cursor pointer
            .downloadOrgnData
                width: 122px
                height: 32px
                margin-left: 24px
                background-color: #00CCCC
                line-height: 32px
                font-size: 14px
                text-align: center
                color: #ffffff
                border-radius: 2px
                cursor pointer
            .download_all
                width: 122px
                height: 32px
                margin-left: 24px
                background-color: #FFFFFF
                line-height: 32px
                border: 1px solid #00cccc
                font-size: 14px
                text-align: center
                color: #00CCCC
                border-radius: 2px
                cursor pointer
        .tableTop_right
            display: flex
            align-items: center
            .delete
                width: 64px
                height: 32px
                background-color: #FC5555
                text-align: center
                line-height:32px
                color: #fff
                font-size: 14px
                margin-left: 16px
                cursor pointer
                border-radius: 4px

            
        
    

</style>