<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     align="center"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      prop="examineName"
      label="姓名"
      align="center">
    </el-table-column>
    <el-table-column
      label="受检人身份证号"
      show-overflow-tooltip
      align="center">
       <template slot-scope="scope">
       {{ (scope.row.cardId == null) ? scope.row.examinaNo : scope.row.cardId}}
      </template>
    </el-table-column>
    <el-table-column
      prop="mobile"
      label="手机号"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="gender"
      label="性别"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="age"
      label="出生年月"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="createTime"
      align="center"
      label="创建日期"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      align="center"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <span style="color:#00cccc;cursor: pointer;" @click="view(scope.row.id)">查看详情</span>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count"
      v-if="count !=0">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {getUserList} from '@/api/userManage.js'
  export default {
    data() {
      return {
        tableData: [],//表格数据
        multipleSelection: [],// tableCheckboxValue
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 

      }
    },
    created(){
      //获取列表数据
      this.currentPage = this.getContextData("currentPage") || 1;//seesion
      this.getData(this.currentPage,10,);
    },

    methods: {

      //设置存贮页面信息
      //给sessionStorage存值
        setContextData: function(key, value) { 
            if(typeof value == "string"){
                sessionStorage.setItem(key, value);
            }else{
                sessionStorage.setItem(key, JSON.stringify(value));
            }
        },
        // 从sessionStorage取值
        getContextData: function(key){
            const str = sessionStorage.getItem(key);
            if( typeof str == "string" ){
                try{
                    return JSON.parse(str);
                }catch(e) {
                    return str;
                }
            }
            return;
        },


      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage,cardId,gender,mobile,keyWord,startDate,endDate){
              const rLoading = this.openLoading();// 开始loading
              getUserList({
                currentPage: currentPage,
                pageSize: onlyPage,
                cardId:cardId,
                gender:gender,
                mobile:mobile,
                startDate:startDate,
                endDate:endDate,
                keyWord:keyWord
            }).then((res)=>{
              if(res.data.status == 200){
                 this.tableData = res.data.data.records;
                 this.tatalPage =parseInt(res.data.data.size);
                 this.count =parseInt(res.data.data.total);
                 rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
        this.multipleSelection.length = 0;//clear
        //console.log("当前选框为",val)
         val.forEach((item)=>{
           //console.log(item.dataId);
           this.multipleSelection.pushNoRepeat(item.dataId)
         })
        //console.log("添加后的数组",this.multipleSelection)
      },

      // 分页相关
      SizeChange(val){
        // pageSize 改变时会触发  回调参数 每页条数
       // console.log("每页多少条",val);
        this.onlyPage = val;
        //this.getData(this.currentPage,this.onlyPage);//更新数据
        this.getData(this.currentPage,this.onlyPage,this.$parent.searchInfo.cardId,this.$parent.searchInfo.gender,this.$parent.searchInfo.mobile,this.$parent.seachcon,this.$parent.searchInfo.examinaTime[0],this.$parent.searchInfo.examinaTime[1]);
      },
      CurrentChange(val){
        // currentPage 改变时会触发  回调参数 当前页
        this.currentPage = val;
        this.setContextData('currentPage',this.currentPage);//session
        this.getData(this.currentPage,this.onlyPage,this.$parent.searchInfo.cardId,this.$parent.searchInfo.gender,this.$parent.searchInfo.mobile,this.$parent.seachcon,this.$parent.searchInfo.examinaTime[0],this.$parent.searchInfo.examinaTime[1]);//更新数据
      },
      prevClick(val){
        // 上一页
        // console.log("上一页的页数是",val);
        this.currentPage = val ;
        //this.getData(this.currentPage,this.onlyPage);//更新数据
        this.getData(this.currentPage,this.onlyPage,this.$parent.searchInfo.cardId,this.$parent.searchInfo.gender,this.$parent.searchInfo.mobile,this.$parent.seachcon,this.$parent.searchInfo.examinaTime[0],this.$parent.searchInfo.examinaTime[1]);
      },
      nextClick(val){
        // 下一页
        // console.log("下一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage);//更新数据
        this.getData(this.currentPage,this.onlyPage,this.$parent.searchInfo.cardId,this.$parent.searchInfo.gender,this.$parent.searchInfo.mobile,this.$parent.seachcon,this.$parent.searchInfo.examinaTime[0],this.$parent.searchInfo.examinaTime[1]);
      },
      //查看详情
      view(val){
         this.$router.push({
           path:'/userList/fileList',
           query:{
             id:val
           }
         })
      },
    },
    computed:{

    }
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>